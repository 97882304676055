import Cookies from "js-cookie";
import axios from "axios";
import { getBaseUrl } from "../../utils";

export const getChatDetails = async (chatId) => {
  const config = {
    method: "get",
    url: `${getBaseUrl()}admin/chatdetails/${chatId}`,
    responseType: "blob",
    headers: { Authorization: `Bearer ${Cookies.get("token")}` },
  };

  try {
    const response = await axios(config);

    const blob = new Blob([response.data], { type: "text/plain" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `chat_${chatId}.txt`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return response;
  } catch (error) {
    console.error("Error fetching chat details:", error);
    return error.response;
  }
};
