import { Pagination } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Loder from "../../../Loder/Loder";
import DataNotFound from "../../ErrorPage/DataNotFound";
import { getPujaHistory } from "../../api/pujaHistory";
import s from "../Puja.module.css";

const PujaHistory = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [pujaHistory, setPujaHistory] = useState([]);
  const [pageCount, setpageCount] = useState(1);
  const [pageLength, setPageLength] = useState();
  const [search, setsearch] = useState("");

  useEffect(() => {
    fetchPujasHistory();
  }, [pageCount, search]);

  async function fetchPujasHistory() {
    setIsLoading(true);
    try {
      const temp = {
        page: pageCount,
        limit: 8,
        search: search.trim(),
      };
      let res = await getPujaHistory(temp);
      if (res.data.status) {
        setPujaHistory(res?.data?.data);
        setPageLength(res?.data?.data?.totalPages);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        console.log("status false!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--clr-theme)",
      color: theme.palette.common.white,
      fontWeight: "bold",
      borderRight: "1px solid #fff",
      overflow: "hidden",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      border: "none",
      borderLeft: "2px solid #00000011",
      "&:last-child": {
        borderRight: "2px solid #00000011",
      },
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    borderBottom: "2px solid #00000011",
  }));

  return (
    <div className="">
      <h3>Puja History</h3>
      <div className={s["beat_heading"]}>
        <div className={s["user-list-heading"]}>
          <div style={{
            display: "flex",
            gap: "10px"
          }} className="beat_left">
          </div>
          <div className={s["user-list-search"]}>
            <div className={s["search-box"]}>
              <span style={{ paddingRight: "0.5rem" }}>
                <BiSearch size={23} />
              </span>
              <input
                type="text"
                spellCheck="false"
                onChange={(e) => {
                  setpageCount(1);
                  setsearch(e.target.value);
                }}
                placeholder="Search by title..."
              />
            </div>
          </div>
        </div>

      </div>
      <div className="beat_table">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Name</StyledTableCell>
              <StyledTableCell align="center">Puja Booking End Time</StyledTableCell>
              <StyledTableCell align="center">Actual Puja Time</StyledTableCell>
              <StyledTableCell align="center">MRP</StyledTableCell>
              <StyledTableCell align="center">Price</StyledTableCell>
              <StyledTableCell align="center">Temple Name</StyledTableCell>
              <StyledTableCell align="center">Puja Status</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {pujaHistory?.data?.map((row) => (
              <StyledTableRow key={`puja_history_key_${row?.id}`}>
                <StyledTableCell align="center">{row?.name}</StyledTableCell>
                <StyledTableCell align="center">{`${row?.pooja_booking_end_time?.slice(
                  0,
                  10
                )} ${row?.pooja_booking_end_time
                  ?.split("T")[1]
                  ?.slice(0, 5)}`}
                </StyledTableCell>
                <StyledTableCell align="center">{`${row?.actual_pooja_time?.slice(
                  0,
                  10
                )} ${row?.actual_pooja_time
                  ?.split("T")[1]
                  ?.slice(0, 5)}`}
                </StyledTableCell>
                <StyledTableCell align="center">{row?.mrp}</StyledTableCell>
                <StyledTableCell align="center">{row?.price}</StyledTableCell>
                <StyledTableCell align="center">{row?.temple_name}</StyledTableCell>
                <StyledTableCell align="center">
                  <div
                    className={
                      row?.puja_status?.toLowerCase() === "scheduled" ? s["initiated"] :
                      row?.puja_status?.toLowerCase() === "completed" ? s["paid"] :
                      row?.puja_status?.toLowerCase() === "cancelled" ? s["cancelled"] :
                      row?.puja_status?.toLowerCase() === "refunded" ? s["refunded"] : s["default"]
                    }
                  >
                    {row?.puja_status?.toUpperCase()}
                  </div>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <div className="astrologer-action-btn">
                    <span
                      onClick={() => {
                        navigate("/edit-pujas-history", {
                          state: {
                            pagetype: "Edit",
                            data: row,
                          },
                        })
                      }}
                    >
                      Edit
                    </span>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
        {pujaHistory?.data?.length <= 0 && <DataNotFound />}
        {pujaHistory?.data?.length > 0 && (
          <div className={s["pagination"]}>
            <Pagination count={pageLength} size="large" style={{ color: "#D21903" }} onChange={(e, value) => { setpageCount(value) }} page={pageCount} />
          </div>
        )}
      </div>
      <Loder loading={isLoading} />
    </div>
  );
};

export default PujaHistory;