import axios from "axios";
import Cookies from "js-cookie";
import { getBaseUrl } from "../../utils";

const pujaHistory = getBaseUrl() + "admin/pujaHistory";
const updatePujaHistory = getBaseUrl() + "admin/pujaHistory";

export const getPujaHistory = async (data) => {
    let config = {
        method: "get",
        url: pujaHistory + `?page=${data.page}&limit=${data.limit}&search=${data.search}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};

export const updatePujaOrders = async (data, id) => {
    let config = {
        method: "patch",
        url: updatePujaHistory + `/${id}`,
        headers: { Authorization: `Bearer ${Cookies.get("token")}` },
        data: data,
    };

    try {
        let res = await axios(config);
        return res;
    } catch (error) {
        console.log(error);
        return error.response;
    }
};