import React from "react";
import AstrologerAddWallet from "./components/Astrologer/AstrologerAddWallet";
import AstrologerGallery from "./components/Astrologer/AstrologerGallery";
import AstrologerGalleryAdd from "./components/Astrologer/AstrologerGalleryAdd";
import AstrologerSettlement from "./components/Astrologer/AstrologerSettlement";
import AstrologerWallet from "./components/Astrologer/AstrologerWallet";
import InvoiceListing from "./components/Astrologer/InvoiceListing";
import ViewInvoice from "./components/Astrologer/ViewInvoice";
import AddLanguage from "./components/Language/AddLanguage";
import AddNews from "./components/News/AddNews";
import NewsListing from "./components/News/NewsListing";
import AddOffer from "./components/Offers/AddOffer";
import OfferListing from "./components/Offers/OfferListing";
import AddSkill from "./components/Skill/AddSkill";
import UserAddWallet from "./components/Users/UserAddWallet";
import UserWallet from "./components/Users/UserWallet";
import Wallet from "./components/Wallet/Wallet";
import AddAstrologer from "./pages/AddAstrologer";
import AddBanner from "./pages/AddBanner";
import AddCategoryPage from "./pages/AddCategoryPage";
import AddRolePage from "./pages/AddRolePage";
import AddTestimonial from "./pages/AddTestimonial";
import Admin from "./pages/Admin";
import Adminadd from "./pages/Adminadd";
import AstroPujaAdd from "./pages/AstroPujaAdd";
import Astrologer from "./pages/Astrologer";
import AstrologerSlab from "./pages/AstrologerSlab";
import Banner from "./pages/Banner";
import BulkInvoice from "./pages/BulkInvoice";
import Category from "./pages/Category";
import Dashboard from "./pages/Dashboard";
import Language from "./pages/Language";
import LiveStream from "./pages/LiveStream";
import LiveStreamAdd from "./pages/LiveStreamAdd";
import MandirAdd from "./pages/MandirAdd";
import MandirPujaAdd from "./pages/MandirPujaAdd";
import PujaAddOnsAdd from "./pages/PujaAddOnsAdd";
import PujaBenefitsAdd from "./pages/PujaBenefitsAdd";
import PujaCategoryAdd from "./pages/PujaCategoryAdd";
import PujaOrders from "./pages/PujaOrders";
import PujaOrdersEdit from "./pages/PujaOrdersEdit";
import PujaPackageAdd from "./pages/PujaPackageAdd";
import PujaPage from "./pages/PujaPage";
import RollList from "./pages/RollList";
import { SkillList } from "./pages/SkillList";
import Testimonial from "./pages/Testimonial";
import UserAdd from "./pages/UserAdd";
import Users from "./pages/Users";
import Profile from "./pages/profile";
import ChatDetails from "./pages/ChatDetails";
import PujaHistoryPage from "./pages/PujaHistoryPage";
import EditPujaHistoryPage from "./pages/EditPujaHistory";

const routeArray = [
  // { params: undefined, component: <Home1 /> },
  { params: "admin", component: <Admin key={'admin'} /> },
  { params: "role", component: <RollList key={'role'} /> },
  { params: "dashboard", component: <Dashboard key={'dashboard'} /> },
  { params: "adminadd", component: <Adminadd key={'adminadd'} /> },
  { params: "add-role", component: <AddRolePage key={'add-role'} /> },
  { params: "profile", component: <Profile key={'profile'} /> },
  { params: "skill-list", component: <SkillList key={'skill-list'} /> },
  { params: "add-skill", component: <AddSkill key={'add-skill'} /> },
  { params: "language", component: <Language key={'language'} /> },
  { params: "add-language", component: <AddLanguage key={'add-language'} /> },
  { params: "category-list", component: <Category key={'category-list'} /> },
  { params: "add-category", component: <AddCategoryPage key={'add-category'} /> },
  { params: "banner-list", component: <Banner key={'banner-list'} /> },
  { params: "add-banner", component: <AddBanner key={'add-banner'} /> },
  { params: "testimonial-list", component: <Testimonial key={'testimonial-list'} /> },
  { params: "testimonial-add", component: <AddTestimonial key={'testimonial-add'} /> },
  
  // Astrologer
  { params: "astrologer-list", component: <Astrologer key={'astrologer-list'} /> },
  { params: "astrologer-add", component: <AddAstrologer key={'astrologer-add'} /> },
  { params: "astrologer-wallet", component: <AstrologerWallet key={'astrologer-wallet'} /> },
  { params: "astrologer-add-wallet", component: <AstrologerAddWallet key={'astrologer-add-wallet'} /> },
  { params: "astrologer-gallery", component: <AstrologerGallery key={'astrologer-gallery'} /> },
  { params: "astrologer-gallery-add", component: <AstrologerGalleryAdd key={'astrologer-gallery-add'} /> },
  { params: "astrologer-settlement", component: <AstrologerSettlement key={'astrologer-settlement'} /> },
  { params: "invoice", component: <ViewInvoice key={'invoice'} /> },
  { params: "astrologer-invoices", component: <InvoiceListing key={'astrologer-invoices'} /> },
  { params: "astrologer-slab", component: <AstrologerSlab key={'astrologer-slab'} /> },
  { params: "bulk-invoice", component: <BulkInvoice key={'bulk-invoice'} /> },

  
  
  // User
  { params: "users", component: <Users key={'users'} /> },
  { params: "useradd", component: <UserAdd key={'useradd'} /> },
  { params: "user-wallet", component: <UserWallet key={'user-wallet'} /> },
  { params: "user-add-wallet", component: <UserAddWallet key={'user-add-wallet'} /> },

  // Puja
  { params: "puja-category", component: <PujaPage key={'puja-category'} /> },
  { params: "add-puja-category", component: <PujaCategoryAdd key={'add-puja-category'} /> },
  { params: "puja-addons", component: <PujaPage key={'puja-addons'} /> },
  { params: "add-puja-addons", component: <PujaAddOnsAdd key={'add-puja-category'} /> },
  { params: "puja-package", component: <PujaPage key={'puja-package'} /> },
  { params: "add-package", component: <PujaPackageAdd key={'add-package'} /> },
  { params: "puja-benefits", component: <PujaPage key={'puja-benefits'} /> },
  { params: "add-puja-benefits", component: <PujaBenefitsAdd key={'add-puja-benefits'} /> },
  { params: "astro-puja", component: <PujaPage key={'astro-puja'} /> },
  { params: "add-astro-puja", component: <AstroPujaAdd key={'add-astro-puja'} /> },
  { params: "mandir", component: <PujaPage key={'mandir'} /> },
  { params: "add-mandir", component: <MandirAdd key={'add-mandir'} /> },
  { params: "mandir-puja", component: <PujaPage key={'mandir-puja'} /> },
  { params: "add-mandir-puja", component: <MandirPujaAdd key={'add-mandir-puja'} /> },

  // Puja Orders
  { params: "puja-orders", component: <PujaOrders key={'puja-orders'} /> },
  { params: "edit-puja-orders", component: <PujaOrdersEdit key={'edit-puja-orders'} /> },
  
  // Puja History
  { params: "pujas-history", component: <PujaHistoryPage key={'pujas-history'} /> },
  { params: "edit-pujas-history", component: <EditPujaHistoryPage key={'edit-pujas-history'} /> },

  // live stream
  { params: "current-past-streams", component: <LiveStream key = {'current-past-streams'} />},
  { params: "scheduled-streams", component: <LiveStream key = {'scheduled-streams'} />},
  { params: "add-live-stream", component: <LiveStreamAdd key = {'add-live-stream'} />},

  
  // Wallet
  { params: "wallet", component: <Wallet key={'wallet'} /> },
  
  // News
  { params: "news_list", component: <NewsListing key={'news_list'} /> },
  { params: "add_news", component: <AddNews key={'add_news'} /> },
  
  // Offer
  { params: "offers_list", component: <OfferListing key={'offers_list'} /> },
  { params: "add_offer", component: <AddOffer key={'add_offer'} /> },
  // chat Details
  { params: "chat-details", component: <ChatDetails key={'chat-details'} /> },

];

export default routeArray;